<template>
<div class="pic-page-bg"
     :data-poster-size="$layout.pageBg.size">

  <video
      v-if="$layout.pageBg.video"
      :src="$layout.pageBg.video" autoplay muted loop class="bg-video" playsinline
  />

</div>
</template>

<script>
export default {
  name: "pic-page-bg",
  computed:{}
}
</script>

<style lang="less">
.pic-page-bg{
  //background-image: url('../../public/noise.gif');
  //background-repeat: repeat;
  //border: 5px solid red;
  //transition: height 0.5s;
  .bg-video{
    //background-image: url('../../public/noise.gif');
    //background-repeat: repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>