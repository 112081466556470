<template>
<div class="side-menu">
  <v-btn icon dark
         class="close"
         aria-label="Close Menu"
         @click.native="$layout.navOpen=false">
        <v-icon color="var(--color-yellow)" size="32">{{$icons.close}}</v-icon>
  </v-btn>
  <div class="items">
    <div v-for="(p,idx) of items" :key="p.uid"
         :title="p.name"
         :uid="p.uid"
         class="item">
      <h2 class="main-item ty-title-paragraph "
          :class="{
           'active':p.uid===$layout.currentUid,
           'has-href-underline':p.uid!==$layout.currentUid
         }"
        >
        <router-link :to="p.href">
        <morph-text
            :text="p.name"
            :duration="0.25"
            :delay="0.25+idx * 0.15"
            algorithm="ltr type"
            ref="items"/>
        </router-link>
      </h2>

      <div class="sub-items has-href-underline"
           :class="{
              'open':p.uid===$layout.currentUid && ancres.length
           }"
           >
        <h3 v-for="(ancre,idxa) of ancres"
            :key="ancre.href"
            class="sub-item "
            :class="{
              'active':ancre.uid===$layout.currentAnchor
            }"
        >
          <router-link class="ty-rich-text" :to="ancre.href">
            <morph-text
                :text="ancre.label"
                :duration="0.5"
                :delay="0.25+ idxa * 0.15"
                algorithm="ltr type"
                ref="ancres"/>&nbsp;
        </router-link>
        </h3>
      </div>



    </div>
  </div>

</div>
</template>

<script>
import MorphText from "@/components/atoms/morph-text";
export default {
  name: "side-menu",
  components: {MorphText},
  computed:{
    isOpen(){
      return this.$layout.navOpen
    },
    items(){
      return this.$layout.menuList
    },
    /**
     *
     * @return {*[]}
     */
    ancres(){
      let r=[];
      if(!this.$layout.currentPage.blocks){
        return r;
      }
      for(let bl of this.$layout.currentPage.blocks.blockList){
        if(bl.isAnchor && (["paragraph-block","accordion-block"].indexOf(bl.blockType)>-1  ) ){
          r.push(
              {
                label:bl.anchorName,
                href:"#"+bl.uid,
                uid:bl.uid
              }
          );
        }
      }
      return r;
    },
  },
  watch:{
    isOpen(){
      if(this.isOpen){
        if(this.$refs.items){
          for(let r of this.$refs.items){
            r.shake();
          }
        }
        if(this.$refs.ancres){
          for(let r of this.$refs.ancres){
            r.shake();
          }
        }

      }
    }
  },
  methods:{}
}
</script>

<style lang="less">
.side-menu{
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px) saturate(0);
  display: flex;
  align-items: center;
  justify-content: center;
  //padding-left: 100px;
  .close{
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 10;
  }

  .items{
    .item{
      .main-item{
        a{}
      }
    }
    .sub-items{
      .sub-item{
        a{}
      }
    }
  }


  .sub-items{
    //overflow-y: scroll;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //outline: 1px solid red;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;
    &.open{
      transition: all 0.5s ease-in;
      //outline: 1px solid green;
      max-height: 500px;
    }
  }


  .item{

    .main-item{
      a{
        min-height: 1em;
        transition: color 0.5s;
        color: inherit;
        text-decoration: none;
        &.router-link-active{
          color: var(--color-yellow);
        }
        &:hover{
          opacity: 0.8;
          &.active{
            opacity: 1;
          }
        }
      }
    }
    .sub-item{
      //padding-bottom: 10px;
      >a{
        line-height: 2.5 !important;
      }
      &:first-child{
        margin-top: 20px;
      }
      &:last-child{
        margin-bottom: 50px;
      }
    }


  }


}
</style>