<template>
  <transition
      mode="out-in"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
      v-bind:css="false"
  >
    <router-view
        id="router-view"
        :key="$route.fullPath"/>
  </transition>
</template>

<script>
import {gsap, Power3} from "gsap";

export default {
  name: "router-view-transition",
  data() {
    return {
      "transitionIn": "fadeIn",
      "transitionOut": "fadeOut",
      transiParams: {
        duration: 1
      }
    }
  },
  watch: {
    $route(to, from) {
      this.updatePageActive();
      console.log("transi watch", from.fullPath, to.fullPath)

        this.transitionIn = "fadeIn";
        this.transitionOut = "fadeOut";
    }
  }, methods: {
    updatePageActive() {
      //this.$layout.currentPageId = this.$route.params.id;
    },
    beforeEnter: function (el) {
      console.log("beforeEnter");
      let tl = gsap.timeline();
      tl.to(el, {
        opacity: 0,
        duration: 0
      });
    },
    leave: function (el, done) {
      console.log("leave start");

      const $morphTexts=document.querySelectorAll("#router-view .morph-text");
      $morphTexts.forEach(function($el){
        console.log("morph",$el.__vue__)
        $el.__vue__.shake();
      })

      let tl = gsap.timeline(
          {
            onComplete: ()=>{
              console.log("leave done");
              done();
            }
          }
      );
      tl.to(el, {
        opacity: 0,
        duration: this.transiParams.duration / 2
      });
    },
    enter: function (el, done) {
      console.log("enter start");
      this.$layout.nativeScroll.scrollTo(0,0);
      let tl = gsap.timeline(
          {
            onComplete: ()=>{
              console.log("enter done");
              done();
            }
          }
      );
      tl.to(el,
          {
            opacity: 0,
            duration: 0
          }
      );
      tl.to(el,
          {
            opacity: 1,
            duration: this.transiParams.duration / 2,
          }
      );

    }
  },
}
</script>

<style scoped>

</style>