import ItemList from "@/ee09/models/ItemList";
import ImageItem from "@/ee09/models/ImageItem";
import ImageField from "@/ee09/fields/ImageField";
import MediaTextBlock from "@/Models/blocks/media/MediaTextBlock";

export default class AccordionBlock extends MediaTextBlock{
    constructor() {
        super();
        this.blockType="accordion-block";

        /**
         * Petit numéro à côté du titre
         * @type {string}
         */
        this.prefix="";
        /**
         * Titre
         * @type {string}
         */
        this.title="";


    }
}