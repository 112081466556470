import DbRecord from "@/ee09/records/DbRecord";
import BlocksField from "@/ee09/fields/BlocksField";
import RecordField from "@/ee09/fields/RecordField";
import ImageField from "@/ee09/fields/ImageField";
import FileField from "@/ee09/fields/FileField";
import PageUrl from "@/Models/PageUrl";
import DateField from "@/ee09/fields/DateField";

export default class PageModel extends DbRecord{
    constructor() {
        super();
        this.type="page";
        /**
         * La page parente
         * @type {RecordField}
         */
        this.parent=new RecordField();
        /**
         * Titre dans la page
         * @type {string}
         */
        this.pagetitle="";
        /**
         * Le contenu pour la faire courte
         * @type {BlocksField}
         */
        this.blocks=new BlocksField(window.$db.blocksSettings.defaultList);
        /**
         *
         * @type {ImageField}
         */
        this.thumbnail=new ImageField();
        /**
         *
         * @type {ImageField}
         */
        this.poster=new ImageField();
        /**
         *
         * @type {FileField}
         */
        this.videoposter=new FileField();

        /**
         * Le texte du poster
         * @type {string}
         */
        this.textposter="";

        /**
         * Brouillon ou pas?
         * @type {boolean}
         */
        this.draft=false;

        /**
         * Date de publication de l'article
         * @type {DateField}
         */
        this.date_published=new DateField();

        /**
         * Les datas...qui sont en fait des blocks nommés
         * @type {BlocksField}
         */
        this.moredata=new BlocksField([
            "core-string-block",
            "core-string-html-block",
            "core-link-item-block"
        ]);

        this.pageUrl=new PageUrl();
        this.pageUrl.pageuid=this.uid;


    }

    /**
     * Dit si le record est visible ou non en fonction de draft
     * @return {boolean}
     */
    get publishedVisible(){
        if(this.draft){
            return false;
        }
        return true;
    }

    /**
     * Dit si la page est publiée ou non
     * @returns {boolean}
     */
    get isPublished(){
       return !this.date_published.isFuture && !this.draft;
    }

    get notPublishedIcon(){
        if(this.date_published.isFuture){
            return window.$icons.clock;
        }
        if(this.draft){
            return window.$icons.draft;
        }
        return "";
    }

    mount(data){
        super.mount(data);
        let me=this;
        setTimeout(function(){
            if(window.$db.userAdmin){
                if(me.pageUrl.slug===me.uid){
                    console.log("optimize slug")
                    me.pageUrl.slug=me.slug;
                    me.meta.modified=true;
                }
            }else{
                //console.log("not admin")
            }
        },2000);
    }

    /**
     * Renvoie la portion d'url de cette page uniquement
     * @return {string}
     */
    get slugPart(){
        if(this.isHome){
            return "";
        }
        return window.$db.utils.string.slugify(this.name,false);
    }

    /**
     * Est que c'est la home
     * @return {boolean}
     */
    get isHome(){
        return this.pageUrl && this.pageUrl.ishome;
    }

    /**
     * Juste pour tester si le record et donc ses modèles hérités sont une page ou non
     * @readonly
     * @return {boolean}
     */
    get isPage(){
        return true;
    }
    /**
     * Lien vers la page
     * @return {string}
     */
    get href(){
        let u;
        if(this.pageUrl.uid){
            u=this.pageUrl.slugid;
        }else{
            u=`${this.slug}/uid/${this.uid}`;
        }
        if(window.htmlVars.fmkHref){
            return "/"+window.htmlVars.fmkHref+"/"+u;
        }
        return "/"+u;
    }
    get hrefAbsolute(){
        let tr=window.$db.utils.string.trim;
        return tr(window.htmlVars.fmkHrefAbsolute,"/")+"/"+tr(this.href,"/");
    }

    /**
     * Renvoie le slug complet (cette page et ses parentes)
     * @return {string}
     */
    get slug(){
        let b=this.breadcrumb;
        let s=[];
        for(let part of b){
            let sl=part.slugPart;
            if(sl){
                s.push(sl);
            }
        }
        s.push(this.slugPart);
        s=s.filter(item=>item!=="");
        return s.join("/")
    }

    /**
     * Renvoie l'identifiant de composant (vue) pour afficher cette page
     * @return {string}
     */
    get pageComponentName(){
        return this.type+"-page";
    }

    /**
     * Une thumbnail visible uniquement dans l'admin
     * @return {null|string}
     */
    get adminThumb(){
        if(this.thumbnail.isOk){
            return this.thumbnail.resize(50,50,"contain","transparent",80)
        }
        return null;
    }

    get adminSubtitle(){
        let r=super.adminSubtitle;
        if(this.parent.record){
            r+=" | dans "+this.parent.record.name;
        }
        return r;
    }

    /**
     * Renvoie le fil d'ariane de cette page sous forme de tableau plat
     * Se base sur le champ parent pour obtenir cette donnée
     * @return {PageModel[]}
     */
    get breadcrumb() {
        let r = [];
        /**
         *
         * @type {PageModel|null}
         */
        let papa=this.parent.record;
        while (papa && papa.isPage){
            r.unshift(papa);
            papa=papa.parent.record;
        }
        return r;
    }

    /**
     * Enregistre le cache html de la page
     * @param cb
     */
    saveHtml(cb=function(){}){
        let me=this;
        me.getHtml(
            function(html){
                window.$api.setStoreRecordCacheHtml(me.uid,html,function(){
                    cb();
                }
            )
        });
    }

    /**
     * récupère le html de la page
     * @param cb
     * @private
     */
    getHtml(cb=function(){}){
        let me=this;
        window.$dbUi.pageHtmlToGenerate=me;
        setTimeout(function(){
            let html=document.getElementById("admin-page-html-generator").innerHTML;
            window.$dbUi.pageHtmlToGenerate=null;
            cb(html);
        },1000) //pour laisser le temps à la page de s'afficher correctement;
    }

    get errors(){
        let r=super.errors;
        if(this.pageUrl){
            r=r.concat(this.pageUrl.errors);
        }
        return r;
    }

    get errorsMeta(){
        let r=super.errorsMeta;
        return r;
    }




    /**
     * Renvoie le block push (PushPhotosBlock) de cette page
     * @return {PushPhotosBlock}
     */
    get blockPush(){
        /**
         *
         * @type {PushPhotosBlock}
         */
        let b=this.moredata.getByName("Block Push");
        if(!b){
            b=this.moredata.getByName("Block Push","push-photos-block");
            b.link.pageRecord.uid=this.uid;
        }
        //remplis le block avec les images de la page
        if(b.imagesOk.length===0){
            for(let imf of this.imagesFromContent){
                b.images.append(b.images.getNewItem(imf));
            }
        }
        return b;
    }

    /**
     * Renvoie les références aux images du contenu de cette page
     * @return {ImageField[]}
     */
    get imagesFromContent() {
        let c=this.meta.getCache("imagesFromContent")
        if(c){
            return c;
        }
        console.log("calcule");
        let r = [];
        if(this.poster.isOk){
            r.push(this.poster);
        }
        if(this.thumbnail.isOk){
            r.push(this.thumbnail);
        }
        for(let b of this.blocks.blockList){
            r=r.concat(b.imagesFromContent)
        }
        this.meta.setCache("imagesFromContent",r)
        return r;
    }







}