import {
    mdiAccount,
    mdiFile,
    mdiFileDocumentEditOutline,
    mdiAndroidStudio,
    mdiSchool,
    mdiCardAccountDetails,
    mdiCheckDecagram,
    mdiChartTree,
    mdiAccountOff,
    mdiCog,
    mdiAutoFix,
    mdiCheck,
    mdiPencil,
    mdiViewList,
    mdiClose,
    mdiLock,
    mdiCached,
    mdiAt,
    mdiDirectionsFork,
    mdiLink,
    mdiPageLayoutFooter,
    mdiPageLayoutHeader,
    mdiPageLayoutBody,
    mdiCodeBraces,
    mdiCodeTags,
    mdiFilePdfBox,
    mdiImage,
    mdiFileExcelOutline,
    mdiFileWordOutline,
    mdiMovie,
    mdiFolderZipOutline,
    mdiCommentTextOutline,
    mdiFileQuestionOutline,
    mdiMusic,
    mdiAccountCircle,
    mdiEye,
    mdiEyeOff,
    mdiPlus,
    mdiArrowUp,
    mdiArrowDown,
    mdiArrowLeft,
    mdiArrowRight,
    mdiTrashCan,
    mdiArrowTopRight,
    mdiWindowRestore,
    mdiPlusCircleOutline,
    mdiCloudUploadOutline,
    mdiFormatBold,
    mdiFormatItalic,
    mdiFormatListBulleted,
    mdiKeyboardReturn,
    mdiFormatClear,
    mdiChevronRight,
    mdiMinus,
    mdiFormatQuoteOpen,
    mdiFormatQuoteClose,
    mdiCheckboxBlankOutline,
    mdiMagnify,
    mdiFormatText,
    mdiFormatTitle,
    mdiText,
    mdiViewListOutline,
    mdiPlaylistPlus,
    mdiClockTimeThree,
    mdiViewDashboard,
    mdiCircleSlice6,
    mdiBell,
    mdiViewColumn,
    mdiArrangeSendBackward,
    mdiViewWeek,
    mdiTag,
    mdiTagMultiple,
    mdiEmail,
    mdiClipboardCheckOutline,
    mdiGhost,
    mdiDotsHorizontal,
    mdiFacebook,
    mdiFormatPageBreak,
    mdiViewCarousel,
    mdiTwitter,
    mdiInstagram,
    mdiVimeo,
    mdiYoutube,
    mdiLinkedin, mdiPlay, mdiPlayCircle, mdiFormatPaint, mdiImageText, mdiCloudDownloadOutline
} from '@mdi/js'
export default class IconsManager{
    constructor() {
        //models
        this.file= mdiFile;
        this.post= mdiFileDocumentEditOutline;
        this.projet= mdiAndroidStudio;
        this.formation= mdiSchool;
        this.portrait= mdiCardAccountDetails;
        this.metier= mdiCheckDecagram;
        this.openData= mdiChartTree;

        //global
        this.user= mdiAccount;
        this.userCircle= mdiAccountCircle;
        this.eye= mdiEye;
        this.eyeOff= mdiEyeOff;
        this.logout= mdiAccountOff;
        this.config= mdiCog;
        this.magic= mdiAutoFix;
        this.check= mdiCheck;
        this.edit= mdiPencil;
        this.list= mdiViewList;
        this.close= mdiClose;
        this.lock= mdiLock;
        this.cached= mdiCached;
        this.at= mdiAt;
        this.redirection= mdiDirectionsFork;
        this.link= mdiLink;
        this.trash= mdiTrashCan;
        this.blank= mdiWindowRestore;
        this.pageFooter= mdiPageLayoutFooter;
        this.pageHeader= mdiPageLayoutHeader;
        this.pageBody= mdiPageLayoutBody;
        this.plus= mdiPlus;
        this.minus= mdiMinus;
        this.plusCircle= mdiPlusCircleOutline;
        this.upload= mdiCloudUploadOutline;
        this.download= mdiCloudDownloadOutline;
        this.arrowUp= mdiArrowUp;
        this.arrowDown= mdiArrowDown;
        this.arrowLeft= mdiArrowLeft;
        this.arrowRight= mdiArrowRight;
        this.arrowTopRight= mdiArrowTopRight;
        this.chevronRight=mdiChevronRight;
        this.quoteOpen=mdiFormatQuoteOpen;
        this.quoteClose=mdiFormatQuoteClose;
        this.checkBoxBlank=mdiCheckboxBlankOutline;
        this.search=mdiMagnify;
        this.text=mdiText;
        this.draft=mdiGhost;

        this.play=mdiPlay;
        this.playCircle=mdiPlayCircle;


        this.facebook=mdiFacebook;
        this.twitter=mdiTwitter;
        this.insta=mdiInstagram;
        this.vimeo=mdiVimeo;
        this.youtube=mdiYoutube;
        this.linkedin=mdiLinkedin;


        //blocks
        this.listOutline=mdiViewListOutline;
        this.playlistPlus=mdiPlaylistPlus;
        this.clock=mdiClockTimeThree;
        this.dashboard=mdiViewDashboard;
        this.chartCircle=mdiCircleSlice6;
        this.bell=mdiBell;
        this.columns=mdiViewColumn;
        this.sendBackward=mdiArrangeSendBackward;
        this.week=mdiViewWeek;
        this.tag=mdiTag;
        this.tags=mdiTagMultiple;
        this.email=mdiEmail;
        this.clipboard=mdiClipboardCheckOutline;
        this.dotsHorizontal=mdiDotsHorizontal;
        this.pageBreak=mdiFormatPageBreak;
        this.carousel=mdiViewCarousel;
        this.imgText=mdiImageText;


        //format
        this.formatBold=mdiFormatBold;
        this.formatTitle=mdiFormatTitle;
        this.formatItalic=mdiFormatItalic;
        this.formatBullet=mdiFormatListBulleted;
        this.formatBreakLine=mdiKeyboardReturn;
        this.formatClear=mdiFormatClear;
        this.formatText=mdiFormatText;
        this.formatTitle=mdiFormatTitle;
        this.formatPaint=mdiFormatPaint;


        //file type
        this.fileCode= mdiCodeBraces;
        this.fileCodeHtml= mdiCodeTags;
        this.filePdf= mdiFilePdfBox;
        this.fileImage= mdiImage;
        this.fileExcel= mdiFileExcelOutline;
        this.fileWord= mdiFileWordOutline;
        this.fileMovie= mdiMovie;
        this.fileMusic= mdiMusic;
        this.fileZip= mdiFolderZipOutline;
        this.fileSrt= mdiCommentTextOutline;
        this.fileUnknown= mdiFileQuestionOutline;

    }
}