import Vue from 'vue'

//models-------------

import PagePage from "./page-page";
import PagelistPage from "./pagelist-page";
import PostPage from "./post-page";

Vue.component('page-page',PagePage);
Vue.component('post-page',PostPage);
Vue.component('pagelist-page',PagelistPage);


let modelsEdit=[
    "./opendata",
    "./user",
    "./page",
    "./pagelist",
    "./post",
    "./filerecord",
    //"./pageurl",
]
for(let path of modelsEdit){
    let split=path.split("/");
    let name=split[split.length-1];
    Vue.component(
        name+"-edit",
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
        path+'-edit.vue'
            )
    )
}

// core blocks --------------------------------

let coreBlocksEdit=[
    "./core-blocks/named-block-layout",
    "./core-blocks/core-string-block-edit",
    "./core-blocks/core-string-html-block-edit",
    "./core-blocks/core-link-item-block-edit",
    "./core-blocks/core-link-item-list-block-edit"
];

for(let path of coreBlocksEdit){
    let split=path.split("/");
    let name=split[split.length-1];
    Vue.component(
        name,
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
            path+'.vue'
        )
    )
}

let blocks=[
    "./blocks/texte/titre-block",
    "./blocks/texte/paragraph-block",
    //"./blocks/texte/citation-block",

    "./blocks/media/iframe-block",
    "./blocks/media/media-text-block",
    "./blocks/media/accordion-block",
    "./blocks/media/media-block",
    "./blocks/media/photo-list/photo-list-block",
    "./blocks/links/btn-block",

    //"./blocks/links/push-photos/push-photos-block",
    //"./blocks/links/carousel-pages/carousel-pages-block",

    //"./blocks/en-cours/poster/poster-block",

]
for(let b of blocks){
    let split=b.split("/");
    let name=split[split.length-1];
    Vue.component(
        name+'-edit',
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
            b+'-edit.vue'
        )
    )
    Vue.component(
        name+'',
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "blocks"  */
        b+'.vue'
    )
    )
}

//blocks sans edit
Vue.component('form-contact-block',() =>import("./blocks/forms/form-contact-block.vue"))
