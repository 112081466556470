<template>
<div class="prev-next" v-if="prev && next">
  <router-link
    :to="prev.href"
    class="prev text-fat"
    @mouseleave.native="hout()"
    @mouseover.native="hoverPrev()"

  >
    <span>
      <span>{{ prev.name }}</span>
      <span class="m">{{ prev.name }}</span>
    </span>
  </router-link>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px" viewBox="0 0 142 70"
       xml:space="preserve">
    <!--line fill="none"
          stroke="var(--color-yellow)"
          x1="70" y1="70" x2="70" y2="0"
    /-->
    <line ref="horiz-line"
          fill="none"
          stroke="var(--color-yellow)"
          x1="0" y1="35" x2="140" y2="35"
    />
    <polyline fill="none" stroke="var(--color-yellow)" points="115,60 140,35 115,10 	"/>
    <polyline fill="none" stroke="var(--color-yellow)" points="25,10 0,35 25,60 	"/>
  </svg>

  <router-link
    :to="next.href"
    class="next text-fat"
    @mouseleave.native="hout()"
    @mouseover.native="hoverNext()"
  >
    <span>
      <span>{{ next.name }}</span>
      <span class="m">{{ next.name }}</span>
    </span>
  </router-link>

</div>
</template>

<script>
import PageModel from "@/Models/PageModel";
import {gsap} from "gsap";
export default {
  name: "prev-next",
  props:{
    prev:{
      type:PageModel
    },
    next:{
      type:PageModel
    }
  },
  mounted() {
    this.hout();
  },
  methods:{
    hoverNext(){
      gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x1:70+10,x2:140},ease:'Power4.easeOut',overwrite: true});
    //  gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x2:140},ease:'Power4.easeOut',overwrite: true});
    },
    hoverPrev(){
      gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x1:0,x2:70-10},ease:'Power4.easeOut',overwrite: true});
     // gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x2:70-10},ease:'Power4.easeOut',overwrite: true});
    },
    hout(){
      gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x1:70,x2:70},ease:'Power4.easeOut',overwrite: true});
    }
  }
}
</script>

<style lang="less">
.prev-next{
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  --svg-width:100px;
  --svg-height: 70px;
  --padd:10px;

  height: var(--svg-height);

  @media(min-width: @screen-md){
    --svg-width:142px;
    --svg-height: 70px;
    --padd:60px;
  }

  >a{
    transform: skewX(-10deg) translateX(0.1em);
    color: var(--color-yellow) !important;
    font-size: var(--s1) !important;
    font-family: var(--font-title);
    text-transform: uppercase;
    font-weight: 800;
    //width: 30vw;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;

    >span{
      position: relative;
      >span{
        &:first-child{
          color: transparent;
          -webkit-text-stroke-color: var(--color-yellow);
          -webkit-text-stroke-width:1px;
        }
        &:nth-child(2){
          position: absolute;
          top: 0;
          left: 0;

        }
      }
    }

    .m{
      transition: clip-path 0.2s;
      clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
    }
    &.prev{
      .m{
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      }
    }
    &:hover{
      .m{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }

    &.prev{
      justify-content: flex-end;
      //text-align: right;
      padding-right: var(--padd);
    }
    &.next{
      padding-left: var(--padd);
    }

  }
  svg{
    display: block;
    width: var(--svg-width);
    height: 100%;
    pointer-events: none;
    line{
      stroke-width: 1;
      shape-rendering: crispEdges;
    }
    color: var(--color-yellow);
  }

}

</style>