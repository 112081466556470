import Vue from "vue";

import PhpDb from "../ee09/PhpDb";
import DbUi from "@/ee09/db-ui/DbUi";
import PostModel from "@/Models/PostModel";
import PagelistModel from "@/Models/PagelistModel";


import OpendataModel from "@/Models/OpendataModel";


import UserModel from "@/Models/UserModel";
import FileRecord from "@/ee09/records/FileRecord";

//import FormContactBlock from "@/Models/blocks/forms/FormContactBlock";


//composants vue en global (les pages)
require("./components-loader");




/**
 *
 * @type {PhpDb}
 */
window.$db=new PhpDb();
let $db=window.$db;
//langues TODO passer les langues avec le language manager
$db.settings.addLanguage("fr","Français",require("../assets/flags/fr.svg"));

//types de records

//Modèles personnalisés

$db.settings.addModelType(
    "post"
    ,"Article"
    ,window.$icons.post
    ,function(){
        return new PostModel();
    }
);

$db.settings.addModelType(
    "pagelist"
    ,"Liste"
    ,window.$icons.list
    ,function(){
        return new PagelistModel();
    }
);

$db.settings.addModelType(
    "opendata"
    ,"Structure"
    ,window.$icons.openData
    ,function(){
        return new OpendataModel();
    }
);
$db.settings.addModelType(
    "filerecord"
    ,"Fichier"
    ,window.$icons.file
    ,function(){
        return new FileRecord();
    }
);
$db.settings.addModelType(
    "user"
    ,"Utilisateur"
    ,window.$icons.user
    ,function(){
        return new UserModel();
    }
);

$db.settings.getModelType("page").uiSettings.order=1;
$db.settings.getModelType("post").uiSettings.order=2;
$db.settings.getModelType("pagelist").uiSettings.order=2;
$db.settings.getModelType("opendata").uiSettings.order=10;
$db.settings.getModelType("filerecord").uiSettings.order=11;
//$db.settings.getModelType("pageurl").uiSettings.order=12;
$db.settings.getModelType("user").uiSettings.order=20;

//prevent copy
$db.settings.getModelType("user").copiable=false;
$db.settings.getModelType("filerecord").copiable=false;
$db.settings.getModelType("opendata").copiable=false;




/**
 * Rend la BDD disponible et réactive dans tous les composants vue
 * @type {PhpDb}
 */
Vue.prototype.$db = Vue.observable(window.$db);

/**
 * L'admin
 * @type {DbUi}
 */
window.$dbUi=new DbUi();
//donner à l'ui des infos sur l'app
window.$dbUi.mainAppPackage=require("../../package.json");
/**
 * Rend l'admin disponible et réactif dans tous les composants vue
 * @type {DbUi}
 */
Vue.prototype.$dbUi = Vue.observable(window.$dbUi);

//quand tout sera prêt...
//window.$db.start(jsonRecords); //appelé depuis main qd la db est prête
