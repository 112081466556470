/**
 * Permet d' effectuer des opérations courantes comme des tris ou des recherches sur des listes de records.
 */
export default class DbRecordsUtils{
    /**
     * Trie les records par le champ name
     * @param {DbRecord[]} records
     * @return {DbRecord[]}
     */
    sortByName(records){
        return records.sort(function(a, b) {
            let an=a.name.toLocaleLowerCase("fr-FR").trim();
            let bn=b.name.toLocaleLowerCase("fr-FR").trim();
            if(an < bn) { return -1; }
            if(an > bn) { return 1; }
            return 0;
        })
    }

    /**
     * Trie les records par le champ name
     * @param {DbRecord[]} records
     * @param {String} datefield
     * @param {boolean} desc si troue renvoie du plus récent au plus vieux
     * @return {DbRecord[]}
     */
    sortByDateField(records,datefield,desc=false){
        return records.sort(function(a, b) {
            let an=a[datefield].date.getTime();
            let bn=b[datefield].date.getTime();
            if(desc){
                if(an > bn) { return -1; }
                if(an < bn) { return 1; }
            }else{
                if(an < bn) { return -1; }
                if(an > bn) { return 1; }
            }

            return 0;
        })
    }
    /**
     * Renvoie la liste des uids
     * @param {DbRecord[]}records
     * @return {String[]}
     */
    getUidsArray(records){
        let r=[];
        for(let rec of records){
            r.push(rec.uid);
        }
        return r;
    }

    /**
     * Remonte d'un niveau le record dans la liste
     * @param {DbRecord[]} recordsList
     * @param {DbRecord} record
     * @return {DbRecord[]}
     */
    moveUp(recordsList,record){
        let from=this.getRecordPosition(recordsList,record);
        let to=from-1;
        to=Math.max(0,to);
        recordsList.splice(to, 0, recordsList.splice(from, 1)[0]);
        return recordsList;
    }
    /**
     * Descend d'un niveau le record dans la liste
     * @param {DbRecord[]} recordsList
     * @param {DbRecord} record
     * @return {DbRecord[]}
     */
    moveDown(recordsList,record){
        let from=this.getRecordPosition(recordsList,record);
        let to=from+1;
        to=Math.min(recordsList.length-1,to);
        recordsList.splice(to, 0, recordsList.splice(from, 1)[0]);
        return recordsList;
    }
    /**
     * Supprime le record dans la liste
     * @param {DbRecord[]} recordsList
     * @param {DbRecord} record
     * @return {DbRecord[]}
     */
    remove(recordsList,record){
        recordsList=recordsList.filter(item=> record.uid !== item.uid);
        return recordsList;
    }

    /**
     * Renvoie la position numérique du record dans la liste
     * @param {DbRecord[]} recordsList
     * @param {DbRecord} record
     * @return {number}
     */
    getRecordPosition(recordsList,record){
        for(let i=0;i<recordsList.length;i++){
            if(recordsList[i].uid===record.uid){
                return i;
            }
        }
        return 0;
    }
    /**
     * Renvoie un tableau avec des records dédoublonnés
     * @param {DbRecord[]} recordsList
     * @return {DbRecord[]}
     */
    unique(recordsList){
        return [...new Set(recordsList)]
    }

}