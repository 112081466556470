<template>
<div>
  <transition name="fade">
    <v-card dark tile elevation="10" v-if="show">
      <v-card-text>
        <v-row class="text-center" align="center">
          <v-col cols="12" xl="7" offset-xl="2">
            {{
              this.$layout.stat.disabled==='yes' ?
              "Vous avez précédemment refusé l'utilisation des cookies." :
              "En visitant ce site, vous acceptez l'utilisation de cookies afin de vous proposer les meilleurs services possibles."
            }}
          </v-col>
          <v-col cols="12" xl="3">
            <div class="d-sm-flex justify-center">

              <v-btn
                  @click="$layout.stat.disabled='yes';close=true;"
                  class="ma-2"
              >refuser</v-btn>
              <v-btn
                  @click="$layout.stat.disabled='no';close=true;"
                  elevation="10"
                  color="var(--color-yellow)"
                  light
                  class="ma-2">
                accepter
              </v-btn>
              <v-btn
                  text class="ma-2"
                  :to="$layout.specialPages.legal.href">
                En savoir plus
              </v-btn>

            </div>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </transition>

</div>


</template>

<script>
export default {
  name: "accept-cookies",
  data(){
    return{
      close:false
    }
  },
  computed:{
    show(){
      if(this.close===true){
        return false;
      }
      if(this.$layout.stat._disabled === 'no'){
        return false;
      }
      return true;
    }
  },
}
</script>
