import Vue from 'vue'
import App from './App.vue'
import router from './pluggins/router'
import EE09Api from "@/ee09/EE09Api";
import Layout from "@/Layout";
import vuetify from './pluggins/vuetify'
import EE09utilsBrowser from "@/ee09/utils/EE09utilsBrowser";
import IconsManager from "@/IconsManager";

Vue.config.productionTip = false

window.$utils=new EE09utilsBrowser();
Vue.prototype.$utils = Vue.observable(window.$utils);

window.$icons=new IconsManager();
Vue.prototype.$icons = Vue.observable(window.$icons);

/**
 * Là où on stocke pas mal de choses qui influent sur l'affichage
 * @type {Layout}
 */
window.$layout=new Layout();
Vue.prototype.$layout = Vue.observable(window.$layout);

/**
 *
 * @type {EE09Api}
 */
window.$api=new EE09Api(
    window.$layout.isDev?
        //"http://localhost/github/picseyes":
        "https://picseyes-studio.pixel-vinaigrette.com":
        window.htmlVars.fmkHrefAbsolute
);
Vue.prototype.$api = Vue.observable(window.$api);

require("./Models/db.config");
require("./Models/blocks.config");

console.log("process.env",process.env)
//console.log(window.$layout.isDev)



//---------------charge la BDD----------------------

window.$vueApp=null;

window.$api.getListRecords([
    "page",
    "pagelist",
    "post",
    "opendata",
    "filerecord",

],function(data){
    //console.log(data.success);
    if(data.success){
        let t1=new Date().getTime();
        window.$db.start(data.body.records,data.user);
        window.$layout.dbReady();
        let t2=new Date().getTime();
        console.log("process data took..",t2-t1);
        window.$api.loopPing(60); //ping en boucle pour tester la connexion
        window.$layout.isReady=true;
        //ok on lance vue
        window.$vueApp=new Vue({
            router,
            vuetify,
            render: h => h(App)
        }).$mount('#app')

    }
});






