import Stats from "@/dataModels/Stats";
import HtmlVars from "@/dataModels/HtmlVars";
import PageBg from "@/pic/PageBg";
import ImageZoom from "@/image-zoom/ImageZoom";
import NativeScroll from "@/NativeScroll";
const EventEmitter = require('event-emitter-es6');

export default class Layout extends EventEmitter{



    constructor() {
        super();

        /**
         * Est-ce que la nav est ouverte ?
         * @type {boolean}
         */
        this.navOpen=false;

        /**
         * Quand true c'est qu'on peut afficher les vues
         * En pratique est true quand la db aura des records
         * @type {boolean}
         */
        this._isReady=false;
        /**
         * Est on en dev (vue js hot reload etc...) ou non
         * @type {boolean}
         */
        this.isDev=process.env.NODE_ENV==="development";

        this.imagesZoom=new ImageZoom();

        /**
         * La page en cours
         * @type {PageModel}
         */
        this.currentPage={};
        /**
         * L'uid courrant
         * @type {string}
         */
        this._currentUid="";

        /**
         * Version du projet
         * @type {string}
         */
        this.version=require('../package.json').version;

        /**
         * Afficher ou pas l'admin
         * @type {boolean}
         */
        this.adminVisible=false;

        //définit les HTML vars en fonction de php ou par défaut
        if(!window.htmlVars){
            /**
             *
             * @type {HtmlVars}
             */
            window.htmlVars=new HtmlVars();
            window.htmlVars.fmkHrefAbsolute="http://localhost/github/ee09-vue-php";
            window.htmlVars.fmkHref="http://localhost/github/ee09-vue-php";
        }
        /**
         * Permet d'enregistrer des stats (google analytics)
         * @type {Stats}
         */
        this.stat=new Stats(window.htmlVars.googleAnalyticsId);

        /**
         * Les datas du footer
         * @type {OpendataModel}
         */
        this.footer=null;
        /**
         * Les datas du menu en haut
         * @type {OpendataModel}
         */
        this.mainMenu=null;

        this.specialPages={
            /**
             * @type {PageModel}
             */
            legal:null,
            /**
             * @type {PagelistModel}
             */
            home:null,
        }
        console.log(process.env.BASE_URL);
        this.publicPath=process.env.BASE_URL;

        /**
         * Le background de page en cours
         * @type {PageBg}
         */
        this.pageBg=new PageBg();

        /**
         * La liste de pages en cours pour gérer les prev next
         * @type {PageModel[]}
         */
        this.currentList=[];

        /**
         * uid du block d'ancre courrant
         * @type {string}
         */
        this.currentAnchor="";
        /**
         *
         * @type {NativeScroll}
         */
        this.nativeScroll=new NativeScroll();

    }


    dbReady(){
        this.specialPages.legal=window.$db.getByName("page","Mentions légales",true);
        this.specialPages.home=window.$db.getByName("pagelist","home page",true);
    }

    /**
     *
     * @return {PageModel[]}
     */
    get menuList(){
        return $layout.specialPages.home.pages.records.filter(r=>!r.draft);
    }

    get defaultList(){
        if(!this.currentList.length){
            return this.menuList;
        }
        return this.currentList;
    }

    get nextPage() {
        return window.$utils.array.next(
            this.currentPage, this.defaultList, true);
    }
    get prevPage(){
        return  window.$utils.array.prev(this.currentPage,this.defaultList,true);
    }

    /**
     * Ouvre une url dans un nouvel onglet
     * @param url
     */
    openNewTab(url){
        window.open(url, '_blank').focus();
    }

    get currentUid() {
        return this._currentUid;
    }

    set currentUid(value) {
        this._currentUid = value;
        this.currentPage=window.$db.getByUid(this.currentUid);
        if(this.currentPage){
            if(this.currentPage.pageUrl){
                document.title=this.currentPage.pageUrl.title;
                this.stat.pageView(this.currentPage.href,document.title);
            }else{
                document.title=this.currentPage.name;
            }
        }
    }

    /**
     * Est-on sur la home?
     * @return {boolean}
     */
    get isHome(){
        return this.currentUid===this.specialPages.home.uid;
    }
    get isReady() {
        return this._isReady;
    }

    set isReady(value) {
        this._isReady = value;
        this.footer=window.$db.getByName("opendata","footer",true);
        this.mainMenu=window.$db.getByName("opendata","Menu principal",true);
    }

    /**
     * true si on est en cours de rendu HTML pour le cache
     * @return {boolean}
     */
    get isHtmlCaching(){
        if(window.$dbUi && window.$dbUi.pageHtmlToGenerate){
            return true;
        }
        return false;
    }

}


