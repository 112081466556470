import DbSettings from "./settings/DbSettings";
import EE09fileUtilsBrowser from "@/ee09/utils/EE09fileUtilsBrowser";
import EE09dateUtils from "@/ee09/utils/EE09dateUtils";
import EE09urlAnalyzer from "@/ee09/utils/EE09urlAnalyzer";
import EE09obj from "@/ee09/utils/EE09obj";
import EE09string from "@/ee09/utils/EE09string";
import EE09is from "@/ee09/utils/EE09is";

import PageModel from "@/Models/PageModel";
import BlocksSettings from "@/ee09/settings/BlocksSettings";
import DbRecordsUtils from "@/ee09/utils/DbRecordsUtils";
import EE09task from "@/ee09/EE09task";
import PageUrl from "@/Models/PageUrl";
import EE09arrayUtils from "@/ee09/utils/EE09arrayUtils";
import EE09numberUtils from "@/ee09/utils/EE09numberUtils";
import EE09validator from "@/ee09/utils/EE09validator";
//import RecordMeta from "@/ee09/records/RecordMeta";
//import DbRecordFile from "@/ee09/records/DbRecordFile";
const fileUtils=new EE09fileUtilsBrowser();

export default class PhpDb{
    constructor() {
        //let me=this;
        /**
         *
         * @type {DbRecord[]}
         */
        this.records=[];
        /**
         *
         * @type {RecordMeta[]}
         */
        this.recordsMeta=[];
        /**
         * Le paramétrage de la base de données
         * @type {DbSettings}
         */
        this.settings=new DbSettings(this);
        this._settingsBaseRecords();
        /**
         * Utilitaires
         */
        this.utils={
            array:new EE09arrayUtils(),
            date:new EE09dateUtils(),
            file:new EE09fileUtilsBrowser(),
            is:new EE09is(),
            number:new EE09numberUtils(),
            obj:new EE09obj(),
            records:new DbRecordsUtils(),
            string:new EE09string(),
            url:new EE09urlAnalyzer(),
            validator:new EE09validator(),
        }
        /**
         * Permet de configurer les blocks utilisés
         * @type {BlocksSettings}
         */
        this.blocksSettings=new BlocksSettings();
        /**
         *
         * @type {null|string}
         * @private
         */
        this._userUid=null;

        this.started=false;

        /**
         * flag qui permet d'éviter d'enregistrer en concurence
         * @type {boolean}
         */
        this.saving=false;

        let me=this;

        this.uids={};



        setInterval(function(){
            let $focus=document.activeElement;
            if($focus.matches("textarea,input,.editor-box [contenteditable='true']")){
                console.log("edit prevent save")
            }else{
                //console.log("has focus",$focus.tagName);
                me.storeNext();
            }
        },5000)

        /**
         * Si on tente de quitter alors que des modifs admin ne sont pas enregistrées alors affiche une alerte.
         * @returns {string}
         */
        window.onbeforeunload=function(){
            if(me.userAdmin){
                let unrecorded=false;
                for(let r of me.records){
                    if(r.meta.modified){
                        me.store(r,function(){
                            console.log('store next ok');
                        });
                        unrecorded=true;
                    }
                }
                if(unrecorded){
                    return "Attention, certaines modifications ne sont pas encore enregistrées";
                }
            }
        }



    }
    /**
     * A appeler une fois tous les settings sont ok
     */
    start(_jsonRecords,user=null){
        this.started=true;
        this._jsonRecords=_jsonRecords
        this._mount(this._jsonRecords);
        if(user){
            this._userUid=user.uid;
            if(this.userAdmin){
                if(!this.userAdmin.canManageUsers){
                    this.settings.getModelType("user").uiSettings.visible=false;
                }
                if(!this.userAdmin.isdev){
                    this.settings.getModelType("opendata").uiSettings.visible=false;
                    this.settings.getModelType("filerecord").uiSettings.visible=false;
                }

            }

        }
    }

    /**
     * Quand il est défini cela sous entend que l'utilisateur est logué, si cette valeur change alors on recharge
     * @param {string} useruid
     */
    set userUid(useruid){
        if(this.started && useruid !== this._userUid){
            let message="Souhaitez vous recharger la page ?";
            console.log("useruid !== this._userUid",useruid,this._userUid)
            switch (true){
                case useruid && !this._userUid:
                    message="Vous venez de vous connecter, souhaitez vous recharger la page ?";
                    break;
                case useruid && this._userUid:
                    message="Vous venez de vous connecter avec un autre compte, souhaitez vous recharger la page ?";
                    break;
                case !useruid:
                    message="Vous venez de vous déconnecter, souhaitez vous recharger la page ?";
                    break;
            }
            if(confirm(message)){
                document.location.reload();
            }

        }
    }

    /**
     * Configure les records de base
     * @private
     */
    _settingsBaseRecords(){
        this.settings.addModelType('page','Page',window.$icons.file,function(){
            return new PageModel();
        })
    }


    /**
     * Renvoie l'utilisateur
     * @return {null|UserModel}
     */
    get user(){
        if(this._userUid){
            return this.getByUid(this._userUid)
        }
        return null;
    }

    /**
     * Renvoie l'utilisateur si c'est un admin
     * @return {UserModel}
     */
    get userAdmin(){
        let u=this.user;
        if(u && u.isadmin){
            return u;
        }
        return  null;
    }
    /**
     * Renvoie l'utilisateur si c'est un admin et un dev
     * @return {UserModel}
     */
    get userAdminDev(){
        let u=this.user;
        if(u && u.isadmin && u.isdev){
            return u;
        }
        return  null;
    }

    /**
     * Créee ou met à jour un record
     * @param {DbRecord} record
     * @param {function} cb
     */
    store(record,cb){
        if(this.saving){
            console.log("already saving");
            return;
        }
        this.saving=true;
        record.meta.saving=true;
        console.warn("store",record.uid,record.name)
        let me=this;
        record.update();
        window.$api.setStoreRecord(record,function(data){
            if(data.success){
                me._mount(data.body.records);
                if(record && record.isPage){
                    record.saveHtml();
                }
            }else{
                console.error("setStoreRecord")
                console.error(data.errors);
            }
            me.saving=false;
            cb(data);
        })

    }
    /**
     * Copie un record
     * @param {DbRecord} record
     * @param {function} cb
     * @param {function} cbError
     */
    duplicate(record, cb,cbError){
        let me=this;
        window.$api.setDuplicateRecord(record,function(data){
            if(data.success){
                me._mount(data.body.records);
                cb(me.getByUid(data.body["saved-uid"]))
            }
        },cbError)
    }

    /**
     * Enregistre le premier record modifié rencontré
     */
    storeNext(){
        if(this.saving){
            return;
        }
        for(let r of this.records){
            if(r.meta.modified){
                this.store(r,function(){
                    console.log('store next ok');
                });
                return;
            }
        }
    }

    /**
     * Recharge tous les records de la bdd
     */
    refreshAll(){
        let me=this;
        let types=[];
        for(let t of this.settings.modelsTypes){
            types.push(t.type);
        }
        window.$api.getListRecords(types,function(data){
            me._mount(data.body.records);
        })
    }
    /**
     * Efface un record
     * @param {DbRecord} record
     */
    trash(record){
        let me=this;
        window.$api.setTrashRecord(record,function(){
            //supprime de la bdd locale
            me.records = me.records.filter(function(value){
                return value.uid !== record.uid;
            });
            me.recordsMeta = me.recordsMeta.filter(function(value){
                return value.uid !== record.uid;
            });
        })
    }
    /**
     * Transforme les records json en objets
     * @param {*[]} jsonRecords
     * @private
     */
    _mount(jsonRecords){

        for(let record of jsonRecords){
            let uid=record.uid;
            let existing=this.getByUid(uid);
            if(existing){
                //update existing record
                existing.mount(record);
            }else{
                //inject new record
                let mtype=this.settings.getModelType(record.type);
                if(mtype){
                    existing=mtype.getInstance(record)
                    this.records.push(existing);
                }else{
                    console.warn("unknown type "+record.type,record);
                }
            }
            this.uids[existing.uid]=existing;
        }
    }

    /**
     * Renvoie un seul record où field === value
     * @param {string} type Le type de record
     * @param {string} field Le champ à tester
     * @param {*} value La valeur à trouver
     * @return {DbRecord}
     */
    findOne(type,field,value){
        return this.records.find(item=>item.type===type && item[field]===value);
    }
    /**
     * Renvoie un seul record en fonction de son nom et de son type
     * @param {string} type Type de record
     * @param {string} name Name du record à trouver
     * @param {boolean} createIfNull Si défini sur true et que le record n'existe pas, le creara (et l'enregistrera)
     * @return {DbRecord}
     */
    getByName(type,name,createIfNull=false){
        let r=this.records.find(
            item=>
                item.type===type
                && item['name'].toLowerCase() === name.toLowerCase()
        );
        if(createIfNull && ! r){
            r=this.settings.getModelType(type).create();
            r.name=name;
            this.store(r,function(){});
        }
        return r;
    }
    /**
     * Permet de trouver un record Page à partir de son uid
     * @param {string} uid
     * @param {string|null} type permet de ne retourner que le type donné
     * @return {PageModel|null}
     */
    getPageByUid(uid,type=null){
        let r=this.getByUid(uid,type);
        if(r && r.isPage){
            return r;
        }
        return null;
    }
    /**
     * Permet de trouver un record à partir de son uid
     * @param {string} uid
     * @param {string|null} type permet de ne retourner que le type donné
     * @return {DbRecord|null}
     */
    getByUid(uid,type=null){
        if(!uid){
            return null;
        }
        let rec =this.uids[uid];
        //let rec = this.records.find(item=>item.uid===uid);
        if(rec && type){
            if(rec.type===type){
                return rec;
            }else{
                return null; //le type ne correspond pas
            }
        }
        return rec;
    }

    /**
     * Renvoie une page à partir de son slug (ou de son slug avec id)
     * @param slug
     * @return {PageModel|null}
     */
    getPageBySlug(slug){
        //console.log("getPageBySlug",slug);
        //essaye de trouver un page url avec ce slug precisément
        let r=this.recordListPage.find(p=>p.pageUrl.slug===slug);
        if(!r){
            //essaye de trouver un id dans le slug
            let purlId=PageUrl.extractIdFromUrl(slug);
            if(purlId){
                r=this.recordListPage.find(p=>p.pageUrl.id===purlId);
            }
        }
        return r;
    }
    /**
     * Renvoie une page à partir de l'id de son pageurl
     * @param pageUrlId
     * @return {PageModel|null}
     */
    getPageByPageUrlId(pageUrlId){
        return this.recordListPage.find(p=>p.pageUrl.id===pageUrlId);
    }

    /**
     * Renvoie la liste des records à partir des uids fournis
     * @param {String[] } uids
     * @return {DbRecord[]}
     */
    getByUids(uids){
        let r=[];
        for(let uid of uids){
            let rec=this.getByUid(uid);
            if(rec){
                r.push(rec)
            }
        }
        return r;
    }
    
    /**
     * Permet de lister les records d'un même type
     * @param {String} type
     * @return {DbRecord[]}
     */
    getListType(type){
        return this.records.filter(item=>item.type===type);
    }

    /**
     * Cherche un record à partir d'une dbString
     * @param {String} dbUidString Une chaine du type db:type-id
     * @return {DbRecord|null}
     */
    findOneByDbString(dbUidString,type=null){
        const regex = /^db:([a-z]+-[a-zA-Z0-9]+)$/;
        let m=regex.exec(dbUidString);
        if(m){
            let uid=m[1];
            return this.getByUid(uid,type)
        }
        return null;
    }

    /**
     * Renvoie les records qui sont des fichiers image
     * @return {FileRecord[]}
     */
    get recordListImages(){
        return this.recordListFiles.filter(item=> item.isImage);
    }

    /**
     * Renvoie les records qui sont des fichiers
     * @return {FileRecord[]}
     */
    get recordListFiles(){
        return this.records.filter(item=>item.type==='filerecord');
    }

    /**
     * Renvoie les records qui sont des pages
     * @return {PageModel[]}
     */
    get recordListPage(){
        return this.records.filter(item=>item.isPage);
    }

    /**
     * Renvoie un file record à partir d'un fichier physique
     * Le systeme se base sur md5file pour déterminer si le fichier existe ou s'il faut le créer
     * @param {File} file
     * @param {String} recordType Type de record que l'on souhaite créer (si on souhaite en créer un)
     * @return {EE09task}
     */
    getFileRecord(file,recordType) {
        //console.log("getFileRecord", file)
        let me = this;
        let task = new EE09task();
        task.status = "md5";
        let md5Task = fileUtils.md5(file);
        md5Task.once("RESULT", function (md5) {
            //console.log("md5 success", md5)
            let existing = me.findOne(recordType, 'md5', md5);
            if (existing) {
                //console.log("le fichier est déjà la")
                task.result = existing;
            } else {
                task.status="uploading"
                //console.log("uploader le fichier")
                window.$api.file.uploadRecord(file,
                    md5,
                    recordType,
                    function(percent){
                        //console.log("progress",percent,b,c)
                        md5Task.status="uploading";
                        md5Task.percent=percent;
                    },
                    function (records){
                        //console.log("complete")
                        me._mount(records);
                        existing = me.findOne(recordType, 'md5', md5);
                        if(existing){
                            task.result=existing;
                        }
                    },
                    function (a,b,c){
                    console.log("error",a,b,c)
                    }

                    );
            }
        })
        md5Task.on("PROGRESS", function (percent) {
            task.percent = percent;
        })
        md5Task.on("ERROR", function (err) {
            console.log("md5 ERROR", err)
            task.addError(err);
        })
        return task;
    }

}