import EE09Item from "@/ee09/models/EE09Item";

export default class ItemList{
    /**
     *
     * @param {function} getNewItem La methode qui permet de créer un nouvel élément
     */
    constructor(getNewItem) {
        /**
         * La liste
         * @type {EE09Item[]}
         */
        this.items=[];

        this.getNewItem=getNewItem
    }

    /**
     * Renvoie un nouvel item qui sera compatible avec cette liste
     * @return {EE09Item}
     */
    getNewItem(){
        return new EE09Item();
    }
    /**
     * Méthode exécutée juste après que le record soit chargé.
     * Cela a pour effet de transformer les items json en objets javascript
     * @private
     */
    _processData(){
        //console.log("_processData ItemList")
        for (let i=0;i< this.items.length;i++){
            let item=this.items[i];
            if(!item.isInit){ //transforme en objet si ce n' est pas déjà fait
                let itemObj=this.getNewItem();
                itemObj.mount(this.items[i]);
                if(item){
                    this.items[i]=itemObj;
                }
            }
        }
    }
    /**
     * Ajoute l' item avant l' itemAfter spécifié
     * @param {EE09Item} item Item que vous souhaitez insérer
     * @param {EE09Item} itemAfter
     */
    insertBefore(item=null,itemAfter){
        if(!item){
            item=this.getNewItem();
        }
        this.items.splice(this._getItemPosition(itemAfter), 0, item);
    }
    /**
     * Ajoute l' item après l' itemBefore spécifié
     * @param {EE09Item} item Item que vous souhaitez insérer
     * @param {EE09Item} itemBefore Item qui précède l'item qui sera inséré
     */
    insertAfter(item=null,itemBefore){
        if(!item){
            item=this.getNewItem();
        }
        this.items.splice(this._getItemPosition(itemBefore)+1, 0, item);
    }
    /**
     * Ajoute l' item au début de la liste
     * @param {EE09Item} item
     */
    prepend(item=null){
        if(!item){
            item=this.getNewItem();
        }
        this.items.unshift(item);
    }
    /**
     * Ajoute l' item à la fin de la liste
     * @param {EE09Item} item
     */
    append(item=null){
        if(!item){
            item=this.getNewItem();
        }
        this.items.push(item);
    }


    /**
     *
     * @param {EE09Item} item
     * @return {number}
     * @private
     */
    _getItemPosition(item){
        for(let i=0;i<this.items.length;i++){
            if(this.items[i].uid===item.uid){
                return i;
            }
        }
        return 0;
    }

    /**
     *
     * @param {EE09Item} item
     */
    moveUp(item){
        let from=this._getItemPosition(item);
        let to=from-1;
        to=Math.max(0,to);
        this.items.splice(to, 0, this.items.splice(from, 1)[0]);
    }

    /**
     *
     * @param {EE09Item} item
     */
    moveDown(item){
        let from=this._getItemPosition(item);
        let to=from+1;
        to=Math.min(this.items.length-1,to);
        this.items.splice(to, 0, this.items.splice(from, 1)[0]);
    }
    /**
     *
     * @param {EE09Item} item
     */
    remove(item){
        this.items=this.items.filter(it=> item.uid !== it.uid);
    }

    get hello(){
        return "hello from item list "+Date.now();
    }
}