<template>
<div class="social-nav">

  <div class="btn-ico">
    <v-btn icon color="var(--color-yellow)"
           v-if="$layout.footer.getLinkItem('insta').href"
           target="_blank"
           aria-label="Instagram"
           :href="$layout.footer.getLinkItem('insta').href">
      <v-icon>{{$icons.insta}}</v-icon>
    </v-btn>
  </div>
  <div class="btn-ico">
    <v-btn icon color="var(--color-yellow)"
           v-if="$layout.footer.getLinkItem('facebook').href"
           target="_blank"
           aria-label="Facebook"
           :href="$layout.footer.getLinkItem('facebook').href">
      <v-icon>{{$icons.facebook}}</v-icon>
    </v-btn>
  </div>
  <div class="btn-ico">
    <v-btn icon color="var(--color-yellow)"
           v-if="$layout.footer.getLinkItem('twitter').href"
           target="_blank"
           aria-label="Twitter"
           :href="$layout.footer.getLinkItem('twitter').href">
      <v-icon>{{$icons.twitter}}</v-icon>
    </v-btn>
  </div>
  <div class="btn-ico">
    <v-btn icon color="var(--color-yellow)"
           v-if="$layout.footer.getLinkItem('vimeo').href"
           target="_blank"
           aria-label="Vimeo"
           :href="$layout.footer.getLinkItem('vimeo').href">
      <v-icon>{{$icons.vimeo}}</v-icon>
    </v-btn>
  </div>
  <div class="btn-ico">
    <v-btn icon color="var(--color-yellow)"
           v-if="$layout.footer.getLinkItem('linkedin').href"
           target="_blank"
           aria-label="Linkedin"
           :href="$layout.footer.getLinkItem('linkedin').href">
      <v-icon>{{$icons.linkedin}}</v-icon>
    </v-btn>
  </div>


</div>
</template>

<script>
export default {
  name: "social-nav"
}
</script>

<style lang="less">
.social-nav{
  display: flex;
  align-items: center;
  >*{
    //outline: 1px dotted red;
    margin-left: 5px;
    margin-right: 5px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  padding: 10px;


}
</style>