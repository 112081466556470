//import TitreBlock from "@/Models/blocks/texte/TitreBlock";
import ParagraphBlock from "@/Models/blocks/texte/ParagraphBlock";
import IframeBlock from "@/Models/blocks/media/IframeBlock";
//import CitationBlock from "@/Models/blocks/texte/CitationBlock";
import CoreStringBlock from "@/Models/core-blocks/CoreStringBlock";
import CoreStringHtmlBlock from "@/Models/core-blocks/CoreStringHtmlBlock";
import CoreLinkItemBlock from "@/Models/core-blocks/CoreLinkItemBlock";
//import CarouselPagesBlock from "@/Models/blocks/links/carousel-pages/CarouselPagesBlock";
//import PhotoListBlock from "@/Models/blocks/media/photo-list/PhotoListBlock";
//import MediaBlock from "@/Models/blocks/media/MediaBlock";
import CoreLinkItemListBlock from "@/Models/core-blocks/CoreLinkItemListBlock";
//import LogoListBlock from "@/Models/blocks/media/photo-list/LogoListBlock";
import Vue from "vue";
import SpacingBlock from "@/Models/blocks/texte/SpacingBlock";
//import PhotoListSwipeBlock from "@/Models/blocks/media/photo-list/PhotoListSwipeBlock";
import BtnBlock from "@/Models/blocks/links/BtnBlock";
import AccordionBlock from "@/Models/blocks/media/AccordionBlock";
import MediaTextBlock from "@/Models/blocks/media/MediaTextBlock";

//-----------------core blocks---------------------

let $db=window.$db;

$db.blocksSettings.addBlockType(
    "core-string-block",
    "Texte",
    window.$icons.formatText,
    function(){
        return new CoreStringBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-string-html-block",
    "Texte enrichi",
    window.$icons.formatText,
    function(){
        return new CoreStringHtmlBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-link-item-block",
    "Lien",
    window.$icons.formatText,
    function(){
        return new CoreLinkItemBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-link-item-list-block",
    "Liste de liens",
    window.$icons.formatBullet,
    function(){
        return new CoreLinkItemListBlock();
    }
)

//-----------------project blocks-------------------------

$db.blocksSettings.defaultList=[];
$db.blocksSettings.defaultList.push({"label":"Blocs textes"})
/*
$db.blocksSettings.addBlockType(
    "titre-block",
    "Titre",
    window.$icons.formatTitle,
    function(){
        return new TitreBlock();
    }
)
 */
$db.blocksSettings.addBlockType(
    "paragraph-block",
    "Paragraphe",
    window.$icons.text,
    function(){
        return new ParagraphBlock();
    }
)
$db.blocksSettings.addBlockType(
    "spacing-block",
    "Espacement",
    window.$icons.pageBreak,
    function(){
        return new SpacingBlock();
    }
)
$db.blocksSettings.getBlockModelType("spacing-block").editComponent=null;
Vue.component('spacing-block',() =>import("./blocks/texte/spacing-block"));
/*
$db.blocksSettings.addBlockType(
    "citation-block",
    "Citation",
    window.$icons.quoteClose,
    function(){
        return new CitationBlock();
    }
);
 */

//---medias----
$db.blocksSettings.defaultList.push({"label":"Médias"});

/*
$db.blocksSettings.addBlockType(
    "media-block",
    //"Photo / Vidéo",
    "Image",
    window.$icons.fileImage,
    function(){
        return new MediaBlock();
    }
)
*/
//photos
/*
$db.blocksSettings.addBlockType(
    "photo-list-block",
    "Liste de photos",
    window.$icons.dashboard,
    function(){
        return new PhotoListBlock();
    }
)
*/
//photos swipe
/*
$db.blocksSettings.addBlockType(
    "photo-list-swipe-block",
    "Caroussel photos",
    window.$icons.carousel,
    function(){
        let r= new PhotoListSwipeBlock();
        r.links=false;
        r.texts=true;
        return r;
    }
)
$db.blocksSettings.getBlockModelType("photo-list-swipe-block").editComponent="photo-list-block-edit";
Vue.component('photo-list-swipe-block',() =>import("./blocks/media/photo-list/photo-list-swipe-block"))
*/

//logos
/*
$db.blocksSettings.addBlockType(
    "logo-list-block",
    "Liste de logos",
    window.$icons.dotsHorizontal,
    function(){
        let r= new LogoListBlock();
        r.links=true;
        return r;
    }
)
$db.blocksSettings.getBlockModelType("logo-list-block").editComponent="photo-list-block-edit";
Vue.component('logo-list-block',() =>import("./blocks/media/photo-list/logo-list-block"))
*/

//iframe
$db.blocksSettings.addBlockType(
    "iframe-block",
    "Iframe (Youtube, Vimeo, etc..)",
    window.$icons.fileCodeHtml,
    function(){
        return new IframeBlock();
    }
)
//media-text
$db.blocksSettings.addBlockType(
    "media-text-block",
    "Media + texte",
    window.$icons.imgText,
    function(){
        return new MediaTextBlock();
    }
)

//accordion
$db.blocksSettings.addBlockType(
    "accordion-block",
    "Accordéon (média + texte)",
    window.$icons.pageBody,
    function(){
        return new AccordionBlock();
    }
)

//btn
$db.blocksSettings.addBlockType(
    "btn-block",
    "Bouton",
    window.$icons.fileCodeHtml,
    function(){
        return new BtnBlock();
    }
)



//---liens----

/*
$db.blocksSettings.addBlockType(
    "carousel-pages-block",
    "Carousel de pages",
    window.$icons.columns,
    function(){
        return new CarouselPagesBlock();
    }
)
*/
//---forms----
/*
$db.blocksSettings.defaultList.push({"label":"Formulaires"});
$db.blocksSettings.addBlockType(
    "form-contact-block",
    "Formulaire contact",
    window.$icons.at,
    function(){
        return new FormContactBlock();
    }
);
$db.blocksSettings.getBlockModelType("form-contact-block").editComponent=null;
 */
